import type { RouteRecordRaw } from 'vue-router';
import AuthLayouts from '../layouts/AuthLayouts.vue';

export const authRoutes: RouteRecordRaw = {
  path: '/auth',
  name: 'auth',
  component: AuthLayouts,
  children: [
    {
      path: 'login',
      name: 'auth-login',
      component: () => import('@/modules/auth/views/loginView.vue'),
    },
    {
      path: 'forgot',
      name: 'auth-forgot',
      component: () => import('@/modules/auth/views/forgotView.vue'),
    },
  ],
};
