import ExtranetLayout from '@/modules/common/layouts/ExtranetLayout.vue';
import type { RouteRecordRaw } from 'vue-router';

export const dashboardRoutes: RouteRecordRaw = {
  path: '/dashboard',
  name: 'dashboard',
  component: ExtranetLayout,
  children: [
    {
      path: '',
      name: 'dashboard-main',
      component: () => import('@/modules/dashboard/views/mainView.vue'),
    },
  ],
};
