<template>
  <!-- This is an example component -->
  <div>
    <ExtranetTopMenuLayout />
    <div class="flex overflow-hidden bg-white pt-16">
      <ExtranetMenuLayout />
      <div class="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
      <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
        <main>
          <RouterView></RouterView>
        </main>
        <ExtranetFooterLayout />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue';
import ExtranetMenuLayout from '../components/ExtranetMenu.vue';
import ExtranetTopMenuLayout from '../components/ExtranetTopMenu.vue';
import ExtranetFooterLayout from '../components/ExtranetFooter.vue';
import { RouterView } from 'vue-router';

const loadScript = (src: string, async = true, defer = true): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = async;
    script.defer = defer;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.head.appendChild(script);
  });
};

onMounted(async () => {
  try {
    await loadScript(new URL('@/assets/js/buttons.js', import.meta.url).href);
    await loadScript(new URL('@/assets/js/app.bundle.js', import.meta.url).href);
    console.log('Ambos scripts se cargaron exitosamente.');
    // Puedes usar las funciones de los scripts aquí si es necesario
  } catch (error) {
    console.error('Error al cargar uno o ambos scripts:', error);
  }
});
</script>
