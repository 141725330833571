import ExtranetLayout from '@/modules/common/layouts/ExtranetLayout.vue';
import type { RouteRecordRaw } from 'vue-router';

export const userRoutes: RouteRecordRaw = {
  path: '/user',
  name: 'user',
  component: ExtranetLayout,
  children: [
    {
      path: '',
      name: 'user-profile',
      component: () => import('@/modules/user/views/profileView.vue'),
    },
  ],
};
