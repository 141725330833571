import ExtranetLayout from '@/modules/common/layouts/ExtranetLayout.vue';
import type { RouteRecordRaw } from 'vue-router';

export const subscriptionRoutes: RouteRecordRaw = {
  path: '/subscription',
  name: 'subscription',
  component: ExtranetLayout,
  children: [
    {
      path: '',
      name: 'subscription-main',
      component: () => import('@/modules/subscription/views/mainView.vue'),
    },
    {
      path: 'detail',
      name: 'subscription-detail',
      component: () => import('@/modules/subscription/views/detailView.vue'),
    },
  ],
};
