import { authRoutes } from '@/modules/auth/routes';
import { dashboardRoutes } from '@/modules/dashboard/routes';
import { subscriptionRoutes } from '@/modules/subscription/router';
import { userRoutes } from '@/modules/user/router';
import WebLayout from '@/modules/web/layouts/WebLayout.vue';
import { webRoutes } from '@/modules/web/routes';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'web',
      component: WebLayout,
      children: [webRoutes],
    },
    dashboardRoutes,
    authRoutes,
    userRoutes,
    subscriptionRoutes,
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // }
  ],
});

export default router;
